.layoutBox {
  min-height: calc(100vh - 1.76rem);
  padding: 0.1rem 0.3rem 0.5rem;
}
.list-cell-li {
  padding: 0.25rem;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.list-cell-li:last-child {
  border-bottom: 0;
}
.list-cell-li .address {
  width: 4.4rem;
  word-break: break-all;
  text-align: right;
}
.list-cell-li .typebtn {
  padding: 0.02rem 0.12rem;
  background: #EFF7FF;
  border-radius: 0.04rem;
  margin-left: 0.1rem;
}
.comTips {
  color: #999999;
  font-size: 0.24rem;
  line-height: 0.45rem;
}
.comTips.comTipsPadd {
  color: #666666;
  font-size: 0.28rem;
  line-height: 0.4rem;
  padding: 0.25rem;
}
.comTips .caption {
  color: #373737;
  font-size: 0.32rem;
  font-weight: bold;
  line-height: 0.45rem;
  margin-bottom: 0.25rem;
}
.comTips p {
  margin-bottom: 0.25rem;
}
.comTips p:last-child {
  margin-bottom: 0;
}
.comBtnStyle {
  color: #FFFFFF;
  font-size: 0.32rem;
  text-align: center;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #90C4FF;
  border-radius: 0.12rem;
}
