
































































































// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 1.76rem);
	padding: .1rem .3rem .5rem;
}


.list-cell-li {
	padding: .25rem;
	border-bottom: 1px solid #EDEDED;
	// line-height: .45rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	&:last-child {
		border-bottom: 0;
	}
	.address {
		width: 4.4rem;
		word-break: break-all;
		text-align: right;
	}
	.typebtn {
		padding: .02rem .12rem;
		background: #EFF7FF;
		border-radius: .04rem;
		margin-left: .1rem;
	}
}
.comTips {
	color: #999999;
	font-size: .24rem;
	line-height: .45rem;
	&.comTipsPadd {
		color: #666666;
		font-size: .28rem;
		line-height: .4rem;
		padding: .25rem;
	}
	.caption {
		color: #373737;
		font-size: .32rem;
		font-weight: bold;
		line-height: .45rem;
		margin-bottom: .25rem;
	}
	p {
		margin-bottom: .25rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: .88rem;
	line-height: .88rem;
	background: #90C4FF;
	border-radius: .12rem;
}
// ----------20200411[end]-----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;